import { restbaseurl } from './constants';

export const resturls = {
  rolePage: 'static/getCourseDetails',
  collegePage: 'static/getCourseDetails',
  coursePage: 'static/getCourseDetails',
  courseTrack: 'static/getCourseDetails',
  retSkillCourseList: 'static/getCatalogDetails',
  retCollegesList: 'static/getUniversityCategory',
  isUserPresentURL: 'user/isUserPresent',
  visitorTrackURL: 'marketer/visitorTracker',
  canEnrollURL: 'onlineCart/canEnrollToCourse/',
  cartFunctionalityURL: 'onlineCart/enableCartFunctionality/',
  tutoringPlanUrl: 'obtainTutoringPlans',
  tutoringPlanDetailsUrl: 'obtainTutoringPlanDetails',
  registerWebsiteUser: 'websiteEnrolledUser/registerWebsiteUser',
  enrollBeforeLogin: 'loginSSO/signUp',
  enrollAfterLogin: 'onlineCart/addToCart',
  bootcampTrialBeforeLogin: 'loginSSO/signup',
  bootcampTrialAfterLogin: 'onlineCart/assignToTrialCatalog',
  marketerSignupURL: 'marketer/signup',
  marketerSigninURL: '/marketer/signIn',
  timeSpentURL: 'a/saveTimeSpent',
  programListsUrl: 'assets/json/ProgramLists.json',
  bootcampURL: 'assets/json/BootCampLists.json',
  placementTestimonialURL: 'assets/json/succesStories.json',
  placementFAQURL: 'assets/json/PlacementFaq.json',
  placementSubjectsURL: 'assets/json/PlacementSubjects.json',
  schoolTutionsFAQURL: 'assets/json/SchoolTutionsFaq.json',
  evangelistFAQURL: 'assets/json/EvangelistFaq.json',
  internTracks: 'static/obtainOnlineInternshipTrackDetails',
  upComingIntern: 'assets/json/UpcomingIntern.json',
  internshipPark: 'assets/json/IntenrshipPark.json',
  obtainInternshipDetail: 'static/obtainOnlineInternshipDetails',
  obtainTeamsforCourse: 'static/obtainUpcomingTeamsForCourse',
  obtainBlogList: 'blog/list',
  obtainBlogDetail: 'blog/show',
  obtainExpertsDetails: 'static/obtainFeedback',
  assignToTrialCatalog: 'onlineCart/assignToTrialCatalog',
  nominationSave: 'nomination/save',
  obtainAwardList: 'nomination/listAwardCategory',
};

export const successurl = `${restbaseurl}student/success`;
export const failureurl = `${restbaseurl}student/failure`;

export const dummyurl = 'http://dummy.restapiexample.com/api/v1/employees';
