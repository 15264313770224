/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import UserInfo from 'models/UserInfo';
import GlobalService from 'services/GlobalService';
import { resturls } from 'globals/utils/apiurls';
import './assets/scss/main.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import VisitorTrack from 'globals/utils/VisitorTrack';
import { GAId } from 'globals/utils/globalVariables';
import NotFound from 'globals/components/404-page/NotFound';
import DefaultLoader from 'globals/components/loader/DefaultLoader';
import PlacementPreparation from 'screens/corporate-ready/PlacementMain';
import GetmePlaced from 'screens/GetmePlaced/GetPlacementMain';
import HomePage from 'screens/home/HomePage';
import Evangelist from 'screens/Evangelist/EvangelistHome';
import MentorHome from 'screens/mentor/MentorHome';
import AssessmentHome from 'screens/assessments/AssessmentsHome';
import Abcd from 'screens/abcd/Abcd';
import Neet from 'screens/Neet/NeetMain';
import BlogCorporate from 'screens/blogs/BlogCorporate';
import Schools from 'screens/schools/Schools';
import PlacementRedirection from 'screens/corporate-ready/PlacementRedirection';
import HomeScreen from 'screens/home/V2/HomeScreen';

const SkillCourseScreen = lazy(() => import('screens/skill/SkillCourseScreen'));
const RoleLayout = lazy(() => import('screens/role/RoleLayout'));
const CollegeLayout = lazy(() => import('screens/college/CollegeLayout'));
const CourseLayout = lazy(() => import('screens/course/CourseLayout'));
const CourseTrackLayout = lazy(() => import('screens/courseTrack/CourseTrackLayout'));
const ContactUs = lazy(() => import('screens/contact-us/ContactUs'));
const CPLayout = lazy(() => import('screens/niche-it-skill-training-programs/CPLayout'));
const CPRedirection = lazy(() => import('screens/niche-it-skill-training-programs/CPRedirection'));
const SchoolCodingPack = lazy(() => import('screens/SchoolCodingPack/SchoolCodingPackHome'));
const Internship = lazy(() => import('screens/Internships/Internship'));
const AboutInternships = lazy(() => import('screens/Internships/AboutInternships'));
const EngineeringTuitions = lazy(() => import('screens/EngineeringTuitions/EngineeringTuitionHome'));
const SchoolTuitions = lazy(() => import('screens/SchoolTuitions/SchoolTuitionHome'));
const SchoolCodingBlog = lazy(() => import('screens/blogs/SchoolCodingBlog'));
const VLSILayout = lazy(() => import('screens/vlsi/VLSILayout'));
const MechanicalLayout = lazy(() => import('screens/mechanical/MechanicalLayout'));
const MechBlogTamil = lazy(() => import('screens/mechanical-blog/MechanicalBlogTamil'));
const MechBlogEnglish = lazy(() => import('screens/mechanical-blog/MechanicalBlogEnglish'));
const TermsAndConditions = lazy(() => import('screens/termsandconditions/TermsAndConditions'));
const CedJr = lazy(() => import('screens/ced-jr/CedJr'));
const AmdJr = lazy(() => import('screens/AMDJr/AmdJrHome'));
const PrdJr = lazy(() => import('screens/PRDJr/PrdJr'));
const JeeHome = lazy(() => import('screens/Jee/JeeHome'));
const COLMainScreen = lazy(() => import('screens/col/COLMainScreen'));
const TrainAndHire = lazy(() => import('screens/trainandhire/TrainAndHireHome'));
const CodingBootcamp = lazy(() => import('screens/collegeCodingBootcamp/CodingBootcamp'));
const PoweredCampuses = lazy(() => import('screens/poweredCampuses/PoweredCampuses'));
const ExclusiveInterview = lazy(() => import('screens/poweredCampuses/ExclusiveInterview'));
const EvangeListWorkshopHome = lazy(() => import('screens/evangelistWorkshop/EvangeListWorkshopHome'));
const AmphiSignatureBlendLayout = lazy(() => import('screens/SignatureBlend/AmphiSignatureBlendLayout'));
const BlogScreenLayout = lazy(() => import('screens/blogs-casestudies/BlogScreenLayout'));
const DigitalPage = lazy(() => import('screens/digital/DigitalLayout'));
const OurServices = lazy(() => import('screens/our-services/OurServices'));
const SocialConnect = lazy(() => import('screens/socialConnect/SocialConnect'));
const AboutScreen = lazy(() => import('screens/aboutus/AboutScreen'));
const HowWeWork = lazy(() => import('screens/howWeWork/HowWeWork'));
const Product = lazy(() => import('screens/product/Product'));
const Awards = lazy(() => import('screens/awards/Awards'));
const RoleScreen = lazy(() => import('screens/role/RoleScreen'));
const OverseasScreen = lazy(() => import('screens/overseas/OverseasScreen.js'));
const SkillCourses = lazy(() => import('screens/home/SkillCourses.js'));
class App extends React.Component {
  constructor(props) {
    super(props);
    VisitorTrack();
  }

  componentDidMount = () => {
    if (UserInfo.isUpdate() === undefined || UserInfo.isUpdate() === false) {
      this.isUserPresent();
    }
    ReactGA.initialize(GAId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  isUserPresent = () => {
    GlobalService.generalSelect(
      (respdata) => {
        UserInfo.setUserDetail(respdata);
      }, resturls.isUserPresentURL, '', 'GET', 'pro',
    );
  }

  render = () => (
    <>
      <Suspense fallback={<DefaultLoader />}>
        <Switch>
          <Route exact path="/home" component={HomeScreen} />
          <Route exact path="/e-boxAwards" component={Awards} />
          <Route path="/services" component={OurServices} />
          <Route path="/socialConnect" component={SocialConnect} />
          <Route path="/about" component={AboutScreen} />
          <Route path="/howWeWork" component={HowWeWork} />
          <Route path="/product" component={Product} />
          <Route path="/digital" component={DigitalPage} />
          <Route
            path="/programs"
            render={(props) => (
              <>
                <SkillCourses />
                <RoleScreen {...props} title="E-Box Premium Courses" />
              </>
            )}
          />
          <Route path="/overseasStudies" component={OverseasScreen} />
          <Route exact path="/skill/:id" component={SkillCourseScreen} />
          <Route path="/course" component={CourseLayout} />
          <Route path="/courseTrack" component={CourseTrackLayout} />
          <Route path="/appCourse" component={CourseLayout} />
          <Route path="/appCourseTrack" component={CourseTrackLayout} />
          <Route path="/role" component={RoleLayout} />
          <Route path="/colleges/:id" component={CollegeLayout} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/placement-preparation" component={PlacementRedirection} />
          <Route exact path="/corporate-ready" component={PlacementPreparation} />
          <Route exact path="/neet" component={Neet} />
          <Route exact path="/anybodycandraw" component={Abcd} />
          <Route exact path="/getmeplaced" component={GetmePlaced} />
          <Route exact path="/evangelist" component={Evangelist} />
          <Route exact path="/mentor" component={MentorHome} />
          <Route exact path="/assessments" component={AssessmentHome} />

          <Route exact path="/Online-Internship" component={Internship} />
          <Route exact path="/internship/:college" component={Internship} />
          <Route exact path="/internship/:id" component={AboutInternships} />
          <Route exact path="/engineering-tuitions" component={EngineeringTuitions} />
          <Route exact path="/school-tuitions" component={SchoolTuitions} />
          <Route exact path="/school-coding-program" component={SchoolCodingPack} />
          <Route exact path="/Why-E-Box-is-considered-to-be-the-right-platform-for-Tech-Skilling" component={BlogCorporate} />
          <Route exact path="/Why-Learn-to-Code-The-Surprisingly-Broad-Benefits-of-Coding" component={SchoolCodingBlog} />
          <Route exact path="/E-Box-Mechanical-Skilling-Programs-The-Future-of-India's-Industry-4.0-Journey-tamil" component={MechBlogTamil} />
          <Route exact path="/E-Box-Mechanical-Skilling-Programs-The-Future-of-India's-Industry-4.0-Journey-english" component={MechBlogEnglish} />
          <Route exact path="/ced-jr" component={CedJr} />
          <Route exact path="/amd-jr" component={AmdJr} />
          <Route exact path="/prd-jr" component={PrdJr} />
          <Route path="/cp" component={CPRedirection} />
          <Route path="/niche-it-skill-training-programs" component={CPLayout} />
          <Route exact path="/col-python" component={COLMainScreen} />
          <Route exact path="/trainAndHire" component={TrainAndHire} />

          <Route path="/vlsi" component={VLSILayout} />
          <Route exact path="/jee" component={JeeHome} />
          <Route exact path="/schools" component={Schools} />

          <Route path="/mech" component={MechanicalLayout} />
          <Route path="/college-coding-bootcamp" component={CodingBootcamp} />
          <Route exact path="/powered-campuses" component={PoweredCampuses} />
          <Route exact path="/exclusive-interview/:id" component={ExclusiveInterview} />

          <Route exact path="/termsandconditions" component={TermsAndConditions} />
          <Route exact path="/oldhomescreen" component={HomePage} />
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/workshop" component={EvangeListWorkshopHome} />
          <Route exact path="/amphiSignatureBlend" component={AmphiSignatureBlendLayout} />
          <Route path="/blogs" component={BlogScreenLayout} />
          <Route exact path="/NotFound" component={NotFound} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </>
  )
}
export default App;
