import React, { Component } from 'react';
import './scss/ProgramsOffered.scss';
import {
  Image, Container, Row, Col, Nav,
} from 'react-bootstrap';
import Header from 'globals/components/V2/Header';

class SkillCourses extends Component {
  render = () => (
    <>
      <Header />
      <Container className="skillSection">
        <Row>
          <Col xs={12}>
            <div className="col-sm-12 skillcol">
              <div className="sectionTitle text-center">Self Paced Courses</div>
              <div className="sectionpara text-center">
              Do you want to Master a Specific Skill or Take up a new Role ?<br />
              Learn from over 100+ Skill Courses and Role Based Courses
              </div>
              <div className="clr20" />

              <div className="row" id="skillOffer">
                <div className="col-sm-12 col-md-4 col-lg-3">
                  <Nav.Link href="skill/it">
                    <div className="programCard skillCard" style={{ height: '395px' }}>
                      <div className="programImage" style={{ height: '165.683px' }}>
                        <Image alt="Skill-1" src="/assets/images/skills/skill-1.webp" style={{ height: '165.683px' }} />
                      </div>
                      <div className="pgmSet">
                        <div className="pgmTitle">IT Skill Courses</div>
                        <p className="courseDesp">
                        Choose from a gamut of IT Skill Courses and achieve
                        your career goal with Industry
                        recognised Skill Courses.
                        </p>
                      </div>
                    </div>
                  </Nav.Link>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-3">
                  <Nav.Link href="skill/ece">
                    <div className="programCard skillCard" style={{ height: '395px' }}>
                      <div className="programImage">
                        <Image alt="Skill-2" src="/assets/images/skills/skill-2.webp" style={{ height: '165.683px' }} />
                      </div>
                      <div className="pgmSet">
                        <div className="pgmTitle">Electronics Skill Courses</div>
                        <p className="courseDesp">
                        Master various Electronics Skill Courses designed
                        as per Industry Needs and Industry
                        Standards.
                        </p>
                      </div>
                    </div>
                  </Nav.Link>
                </div>

                {/* <div className="col-sm-12 col-md-4 col-lg-3">
                    <Nav.Link href="/role">
                      <div className="programCard skillCard" style={{ height: '395px' }}>
                        <div className="programImage">
                          <Image alt="Skill-4" src="/assets/images/skills/skill-4.webp"
                          style={{ height: '165.683px' }} />
                        </div>
                        <div className="pgmSet">
                          <div className="pgmTitle">Role Based Tracks</div>
                          <p className="courseDesp">
                            Learn through job Role Based skilling programs
                            like Full Stack Developer, React Developer
                            that would be highly relevant in near future.
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </div> */}

                <div className="col-sm-12 col-md-4 col-lg-3">
                  <Nav.Link href="/corporate-ready">
                    <div className="programCard skillCard" style={{ height: '395px' }}>
                      <div className="programImage">
                        <Image alt="Skill-5" src="/assets/images/programs/placementcard.png" style={{ height: '165.683px' }} />
                      </div>
                      <div className="pgmSet">
                        <div className="pgmTitle">Corporate Ready Program</div>
                        <p className="courseDesp">
                        Enroll in our comprehensive Corporate Ready Program
                        and crack Placement Tests with confidence.
                        </p>
                      </div>
                    </div>
                  </Nav.Link>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-3">
                  <Nav.Link href="/getmeplaced">
                    <div className="programCard skillCard" style={{ height: '395px' }}>
                      <div className="programImage">
                        <Image alt="Skill-6" src="/assets/images/programs/getmecard.png" style={{ height: '165.683px' }} />
                      </div>
                      <div className="pgmSet">
                        <div className="pgmTitle">Company Specific Practice Assessments</div>
                        <p className="courseDesp">
                        Stay ahead of Competition in the Job Hunt. Take up our Company Specific
                        Mock Assessments and bag the right job.
                        </p>
                      </div>
                    </div>
                  </Nav.Link>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-3">
                  <Nav.Link href="/Online-Internship">
                    <div className="programCard skillCard" style={{ height: '395px' }}>
                      <div className="programImage">
                        <Image alt="Skill-7" src="/assets/images/programs/internship.png" style={{ height: '165.683px' }} />
                      </div>
                      <div className="pgmSet">
                        <div className="pgmTitle">Internships</div>
                        <p className="courseDesp">
                        Skill up yourself for the Industry specific challenges.
                        Apply concepts to real life problems and provide solutions.
                        </p>
                      </div>
                    </div>
                  </Nav.Link>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-3">
                  <Nav.Link href="/niche-it-skill-training-programs">
                    <div className="programCard skillCard" style={{ height: '395px' }}>
                      <div className="programImage">
                        <Image alt="Skill-8" src="/assets/images/programs/corporatecard.png" style={{ height: '165.683px' }} />
                      </div>
                      <div className="pgmSet">
                        <div className="pgmTitle">Niche IT Skill Programs</div>
                        <p className="courseDesp">
                        Enroll in our upskilling and reskilling programs
                        to remain as a much in demand resource in the industry.
                        </p>
                      </div>
                    </div>
                  </Nav.Link>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-3">
                  <Nav.Link href="/mech">
                    <div className="programCard skillCard" style={{ height: '395px' }}>
                      <div className="programImage">
                        <Image alt="Skill-9" src="/assets/images/programs/mech.png" style={{ height: '165.683px' }} />
                      </div>
                      <div className="pgmSet">
                        <div className="pgmTitle">Mechanical Skills</div>
                        <p className="courseDesp">
                        Learn with our Cutting edge Curriculum,
                        Real world Projects & Personalized Mentor Support.
                        </p>
                      </div>
                    </div>
                  </Nav.Link>
                </div>

                <div className="col-sm-12 col-md-4 col-lg-3">
                  <Nav.Link href="/skill/acc">
                    <div className="programCard skillCard" style={{ height: '395px' }}>
                      <div className="programImage" style={{ height: '165.683px' }}>
                        <Image alt="Skill-11" src="/assets/images/programs/trainandhire.png" style={{ height: '165.683px' }} />
                      </div>
                      <div className="pgmSet">
                        <div className="pgmTitle">Accountancy Track</div>
                        <p className="courseDesp">
                        Accounting Track would help Learners to Master the Nuances of Accountancy.
                        </p>
                      </div>
                    </div>
                  </Nav.Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default SkillCourses;
