import React, { useEffect, useState } from 'react';
import HomeFrameImg from 'assets/images/home/homeBannerImage.webp';
import HomeFrameMobImg from 'assets/images/home/homeBannerMob.webp';
import AboutFrameImg from 'assets/images/aboutUs/aboutusBanner.webp';
import AboutFrameMobImg from 'assets/images/aboutUs/aboutUsMobBanner.webp';
import imageFrame from 'assets/images/home/image.webp';
import star from 'assets/images/home/star.webp';
import { mediaBreakpoint } from 'globals/utils/globalVariables';
// import { Container } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';
import styles from './EarlyYears.module.scss';

const EarleyYears = ({ children, content }) => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setwindowWidth(window.innerWidth);
  }, []);

  return (
    <>
      <div className={styles.headerImg}>
        {windowWidth > mediaBreakpoint.mobile ? (
          <Image
            alt="img"
            src={content === undefined ? HomeFrameImg : AboutFrameImg}
            className={styles.earlyYearsImg}
          />
        ) : (
          <Image
            alt="img"
            src={content === undefined ? HomeFrameMobImg : AboutFrameMobImg}
            className={styles.earlyYearsImg}
          />
        )}
      </div>
      <div className={styles.imageWrapper}>
        <Image src={star} className={styles.star} />
        <Image src={imageFrame} className={styles.imageFrame} />
      </div>
      {children}
    </>
  );
};

export default EarleyYears;
