import React from 'react';
import { Image, Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import style from './footer.module.scss';
import footerMap from './assets/footerMap.png';
import eboxLogo from './assets/eboxlogo.webp';
import fb from './assets/FacebookLogo.svg';
import insta from './assets/InstagramLogo.svg';
import linkedIn from './assets/LinkedinLogo.svg';

const Footer = () => {
  const history = useHistory();
  const handleRedirection = (path) => {
    history.push(`/${path}`);
  };
  const handleIconClick = (socialMedia) => {
    if (socialMedia === 'fb')window.open('https://www.facebook.com/eBoxAmphisoft?mibextid=LQQJ4d', '_blank');
    else if (socialMedia === 'insta')window.open('https://www.instagram.com/ebox.ai/', '_blank');
    else if (socialMedia === 'linkedIn')window.open('https://www.linkedin.com/company/amphisoft-technologies/', '_blank');
  };
  return (
    <>
      <Grid className={style.footer}>
        <Grid.Column
          computer={5}
          mobile={16}
          className={style.footerColumn}
          textAlign="center"
        >
          <div className={style.footerDiv1}>
            <div className={style.Ebox}>
              <div className={style.ioacImage}>
                <Image className={style.logoImg} src={eboxLogo} />
              </div>
              <div className={style.ioacContent}>
                <p>We Revolutionise Engineering &
                Technology Learning
                </p>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column
          computer={5}
          mobile={16}
          className={style.footerColumn}
          textAlign="left"
        >
          <div className={style.footerDiv2}>
            <div className={style.contact}>CONTACT</div>
            <div className={style.contactDetails}>
              <div>
                <a href="https://e-box.co.in/contact" className={style.contactContent}>+91 9360954237</a>
              </div>
              <div>
                <a href="https://e-box.co.in/contact" className={style.contactContent}>contact@amphisoft.co.in</a>
              </div>
            </div>
            <div className={style.clr15} />
            <div className={style.socialMedia}>SOCIAL MEDIA</div>
            <div className={style.socialMediaLogo}>
              <div className={style.logo}>
                <Image src={fb} onClickCapture={() => handleIconClick('fb')} />
              </div>
              <div className={style.logo}>
                <Image src={insta} onClickCapture={() => handleIconClick('insta')} />
              </div>
              <div className={style.logo}>
                <Image src={linkedIn} onClickCapture={() => handleIconClick('linkedIn')} />
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <div className={style.footerDiv3}>
            <div className={style.visitUs}>
              VISIT US
              <p className={style.visitContent}>
                123 to 127, kattoor Street
                Gandhipuram, Coimbatore - 641037
              </p>
            </div>
            <div>
              <Image className={style.footerMap} size="small" src={footerMap} />
            </div>
          </div>
        </Grid.Column>
      </Grid>
      <div className={style.footerMessage}>
        <span>Copyrights reserved</span>|
        <span
          className={style.menu}
          onClickCapture={() => handleRedirection('privacyPolicy')}
        >
          Privacy policy
        </span>
        |
        <span
          className={style.menu}
          onClickCapture={() => handleRedirection('termsAndConditions')}
        >
          Terms & Conditions
        </span>
      </div>
    </>
  );
};

export default Footer;
