/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import EarleyYears from 'globals/components/V2/EarlyYears';
import HeaderComponent from 'globals/components/V2/Header';
import {
  Container, Grid, Image, Header,
} from 'semantic-ui-react';
import Footer from 'globals/components/V2/Footer';
// import content from 'screens/digital/data/whyWait';
import program1 from 'assets/images/home/program1.webp';
import program2 from 'assets/images/home/program2.webp';
import program3 from 'assets/images/home/program3.webp';
import program4 from 'assets/images/home/program4.webp';
import program5 from 'assets/images/home/program5.webp';
import program6 from 'assets/images/home/program6.webp';
import platform1 from 'assets/images/home/platform1.webp';
import platform2 from 'assets/images/home/platform2.webp';
import platform3 from 'assets/images/home/platform3.webp';
import platform4 from 'assets/images/home/platform4.webp';
import platform5 from 'assets/images/home/platform5.webp';
import platform6 from 'assets/images/home/platform6.webp';
import education1 from 'assets/images/home/education1.webp';
import education2 from 'assets/images/home/education2.webp';
import education3 from 'assets/images/home/education3.webp';
import education4 from 'assets/images/home/education4.webp';
import education5 from 'assets/images/home/education5.webp';
import { mediaBreakpoint } from 'globals/utils/globalVariables';
import Slider from 'react-slick';
import GlobalService from 'services/GlobalService';
import { probackendurl } from 'globals/utils/constants';
import { resturls } from 'globals/utils/apiurls';
import styles from './scss/HomeScreen.module.scss';
import campusBanner from './assets/campusBanner.webp';
import intergrateBanner from './assets/Certification-pana 2.webp';

const HomeScreen = () => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [selectedPlatform, setSelectedPlatform] = useState(0);
  const [blogList, setBlogList] = useState([]);
  const [selectedModel, setSelectedModel] = useState(0);
  const sliderRef = useRef();

  const obtainBlogList = () => {
    GlobalService.generalSelect((respData) => {
      const { data, estatus, emessage } = respData;
      if (estatus === true && emessage === 'success') {
        setBlogList(data);
      }
    }, `${probackendurl}/${resturls.obtainBlogList}`, '', 'GET');
  };

  useEffect(() => {
    document.title = 'Home | E-Box ';
    setwindowWidth(window.innerWidth);
    obtainBlogList();
  }, []);
  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  const platformData = [{
    heading: 'Platform',
    content: [
      {
        id: 1, label: 'Virtual Atelier', body: 'E-Box’s Virtual Labs provide students with hands-on, practical experience in a simulated environment, bridging the gap between theory and practice. These labs replicate real-world scenarios across various disciplines, allowing learners to experiment, explore, and refine their skills without the constraints of physical resources. With real-time feedback and guided exercises, students can deepen their understanding of complex concepts in a safe, interactive space. E-Box’s Virtual Labs make experiential learning accessible and engaging, regardless of location.', image: platform1,
      },
      {
        id: 2, label: 'Longitudinal Digital Profiles', body: 'E-Box creates Longitudinal Digital Profiles that track and document each student’s learning journey over time, capturing growth, achievements, and areas for improvement. These profiles provide a comprehensive view of a learner’s progress, enabling personalized guidance and support throughout their educational experience. By analyzing these profiles, educators can better understand each student’s unique learning path and tailor their teaching strategies accordingly. E-Box ensures that every learner’s development is fully documented and leveraged for continuous improvement.', image: platform2,
      },
      {
        id: 3, label: 'Advanced Analytics and  Diagnostics', body: 'E-Box leverages Advanced Analytics and Diagnostics to provide deep insights into student performance, learning patterns, and potential challenges. Our platform uses AI-driven tools to analyze data in real-time, offering educators actionable information to enhance teaching effectiveness and student outcomes. These analytics help identify gaps in knowledge, predict academic success, and personalize learning experiences based on individual needs. E-Box empowers educators with the tools they need to make data-informed decisions that drive better educational results.', image: platform3,
      },
      {
        id: 4, label: 'Highly Scalable and Interactive', body: 'E-Box is designed to be both Highly Scalable and Interactive, accommodating institutions of all sizes while maintaining a dynamic, engaging learning experience for students. Our platform can easily expand to support large numbers of users without compromising performance or interactivity. The interactive elements, including simulations, discussions, and real-time assessments, keep learners engaged and motivated. E-Box’s scalability ensures that institutions can grow and adapt their educational offerings with ease, while students enjoy a consistently rich learning experience.', image: platform4,
      },
      {
        id: 5, label: 'Seamless Integration', body: 'E-Box offers Seamless Integration with existing educational systems and platforms, ensuring a smooth and efficient adoption process for institutions. Our platform is designed to work in harmony with a variety of Learning Management Systems (LMS), data repositories, and other educational technologies, minimizing disruption and maximizing efficiency. E-Box’s interoperability allows institutions to enhance their current infrastructure with our advanced tools and resources without overhauling their entire system. This seamless integration makes E-Box an easy and effective addition to any educational ecosystem.', image: platform5,
      },
      {
        id: 6, label: 'Enhanced Learning Strategies', body: 'E-Box is at the forefront of developing Enhanced Learning Strategies that combine traditional pedagogy with modern technology to optimize educational outcomes. Our platform offers a blend of interactive content, personalized learning paths, and real-time feedback, creating a holistic approach to student development. These strategies are designed to meet the diverse needs of today’s learners, fostering deeper understanding, critical thinking, and practical application of knowledge. E-Box empowers educators to implement innovative teaching methods that resonate with students and enhance their learning experience.', image: platform6,
      },
    ],
  }, {
    heading: 'Education Model',
    content: [
      {
        id: 1, label: 'Holistic Pedagogy', body: 'E-Box embraces a holistic approach to education, integrating cognitive, emotional, and practical learning to nurture well-rounded individuals. Our platform goes beyond traditional teaching by incorporating interdisciplinary content and experiential learning, ensuring that students develop a broad range of skills. This pedagogy prepares students not only for academic success but also for meaningful contributions to society. E-Box fosters a learning environment where every aspect of the student’s development is considered and cultivated.', image: education1,
      },
      {
        id: 2, label: 'Personalized Learning', body: 'E-Box’s AI-driven platform offers personalized learning paths tailored to each student’s unique needs, pace, and interests. By continuously adapting to individual progress, E-Box ensures that every learner receives the support and resources necessary to achieve their academic goals. This personalized approach empowers students to take ownership of their education, fostering deeper engagement and mastery. With E-Box, learning is a customized journey designed for success.', image: education2,
      },
      {
        id: 3, label: 'Expert Curation', body: 'E-Box collaborates with industry leaders, academics, and practitioners to curate high-quality content that is both relevant and impactful. Our curriculum is carefully designed to reflect the latest advancements and best practices across various fields, ensuring that students receive an education that is both rigorous and applicable. This expert curation guarantees that learners are equipped with the knowledge and skills needed to excel in their chosen careers. E-Box provides a learning experience that is both comprehensive and cutting-edge.', image: education3,
      },
      {
        id: 4, label: 'Philosophically Provocative and Aesthetically Enriching', body: 'E-Box challenges students to think deeply and critically by offering content that is philosophically provocative and aesthetically enriching. Our platform encourages learners to explore complex ideas, engage with diverse perspectives, and appreciate the beauty of intellectual inquiry. This approach not only sharpens critical thinking skills but also enriches the educational experience, making learning a journey of both the mind and the senses. E-Box inspires students to seek knowledge that is as thought-provoking as it is visually and intellectually stimulating.', image: education4,
      },
      {
        id: 5, label: 'Lifelong Learning Foundation', body: 'E-Box lays the groundwork for lifelong learning by instilling in students the skills and mindset necessary for continuous growth and adaptation. Our platform emphasizes the importance of curiosity, critical thinking, and adaptability, preparing learners to thrive in an ever-changing world. E-Box’s approach ensures that education does not end with graduation but becomes a lifelong pursuit of knowledge and personal development. With E-Box, students build a strong foundation for a lifetime of learning and success.', image: education5,
      },
    ],
  }];
  const programData = [
    {
      id: 1,
      name: 'E-Box Pre-University/+1+2',
      subName: 'Preparing Students for the Future, Today',
      content: 'Higher Secondary School Education or Pre-University Colleges (Grades 11 and 12) serve as the crucial bridge between foundational schooling and higher education. At this stage, students lay the groundwork for their future academic and career paths. E-Box is dedicated to enhancing this critical phase of learning by providing a comprehensive platform that addresses the specific needs of Grade 11 and 12 students.',
      image: program1,
    },
    {
      id: 2,
      name: 'E-Box Engineering Education',
      subName: 'Building the Engineers of Tomorrow',
      content: 'Engineering is the backbone of innovation and progress, requiring a deep understanding of both theoretical principles and practical applications. E-Box’s Engineering Graduation Programs are meticulously crafted to equip students with the technical expertise, problem-solving skills, and real-world experience needed to excel in the rapidly evolving field of engineering.',
      image: program2,
    },
    {
      id: 3,
      name: 'E-Box Science Education',
      subName: 'Cultivating the Scientists of the Future',
      content: 'In an ever-changing world, the pursuit of scientific knowledge is more critical than ever. E-Box’s Science Degree Graduation Programs are designed to empower students with a deep understanding of scientific principles, rigorous analytical skills, and hands-on experience. Our platform provides an innovative approach to science education, ensuring that students are well-prepared to tackle the challenges of the modern world.',
      image: program3,
    },
    {
      id: 3,
      name: 'E-Box Arts & Liberal Arts Education',
      subName: 'Nurturing Creative Thinkers and Thought Leaders',
      content: 'In a world where creativity, critical thinking, and cultural understanding are increasingly valued, Arts and Liberal Arts education plays a pivotal role. E-Box’s Arts and Liberal Arts Graduation Programs are designed to foster intellectual growth, creative expression, and a deep understanding of the human experience. Our platform provides a rich, interdisciplinary approach to education that prepares students to make meaningful contributions to society.',
      image: program4,
    },
    {
      id: 4,
      name: 'E-Box Commerce Education',
      subName: 'Equipping the Business Leaders of Tomorrow',
      content: 'Commerce is the cornerstone of global economies, requiring a solid understanding of business principles, financial acumen, and strategic thinking. E-Box’s Commerce Degree Graduation Programs are designed to prepare students for the dynamic world of business and finance by providing a comprehensive, practice-oriented education. Our platform combines theoretical knowledge with real-world applications, ensuring that graduates are ready to make an impact in the business world.',
      image: program5,
    },
    {
      id: 5,
      name: 'E-Box Management Education',
      subName: 'Cultivating Tomorrow’s Management Innovators',
      content: 'In an increasingly complex and dynamic global business environment, effective management is key to organizational success. E-Box’s Management Education Graduation Programs are designed to develop the next generation of leaders by providing a comprehensive education that blends theoretical insights with practical management skills. Our platform prepares students to navigate the challenges of the modern business world with confidence and strategic acumen.',
      image: program6,
    },
  ];
  const renderCampusDiv = () => (
    <div className={styles.CampusContainer}>
      {!isResponsive && <Image src={campusBanner} size="big" />}
      <div>
        <h2>Why E-Box Powered Campuses are Different and Unique?</h2>
        {isResponsive && <Image src={campusBanner} size="big" /> }
        <div>
          <ol>
            <li>
              People Global Advisory Board High Profile Academicians, Renowned Researchers,
              Industry Experts, Product Engineers, Skilling Assistants
            </li>
            <li>
              Content AI Driven Courses - First Time in the World 300+ Courses
              in All Disciplines of Engineering and Technology Learning. Auto Evaluation
              and Automation Engines for all Domains of Engineering Studies
            </li>
            <li>
              Platform AI Driven Active Learning Platform We Own the Only
              Exclusive Learning & Skilling Platform for Engineering & Technology Education
            </li>
            <li>
              Career Entrepreneurship, Higher Studies & Dream Offers We Hail from
              a Product Company - We Know the Nuances of Building Products and Creating
              Entrepreneurs. We Know the Process to Build Products at Rapid Speed.
              We Are a Team of Highly Qualified Academicians and Professionals -
              We know the Roadmaps for Genuine Higher Studies. 60% of High Package -
              10Lakhs+ Freshers Jobs in India are Filled through E-Box Skilling.
            </li>
            <li>Process Modern Learning Methodologies & Blended Skilling
              ModelsAI Driven, Emphasis on Math and Problem Solving,
              Practice Oriented Learning, Skill Based Learning,
              Product Oriented Learning, Less Monologue & More
              Dialogue Sessions, Game Oriented Learning, Social
              Learning Models, Competitive Learning, Live Interactions
              with Industry Mentors, Anytime-Anywhere Learning,
              Less Lectures More Tutorials & Practicals, Intellectual Ecosystem
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
  const renderMainContent = () => (
    <Container className={styles.mainContent}>
      <h2>Power-Up Your Institution With{!isResponsive && <br />} Practice Oriented Learning</h2>
      <h4>
        Because higher education is not meant for traditional, rigid structures!
        Learning in the modern world transcends physical boundaries, extending from the
         classroom to the virtual realm and beyond.
      </h4>
      <p>
        At E-Box, we introduce a pedagogy that seamlessly integrates aesthetics
        with cutting-edge, tech-enabled learning approaches, harnessing the power of Artificial
        Intelligence (AI) and advanced technologies for the future of Education
        4.0. Our intelligent co-tutoring systems deliver personalized learning experiences, while
        our thoughtfully designed learning environments offer philosophically
        provocative and deeply engaging explorations. With predictive analytics based
        on “classification & clustering,” we identify and nurture the unique potential
        of every student, paving the way for their academic and professional success.
      </p>
      {/* <div className={styles.buttonContainer}>
        <Button basic color="pink">In View</Button>
      </div> */}
    </Container>
  );
  const renderIntegrate = () => (
    <div className={styles.integrateContainer}>
      {!isResponsive && <Image src={intergrateBanner} size="big" />}
      <div>
        <h2>Why Should HigherEd<br /> Institutions Integrate E-Box?</h2>
        {isResponsive && <Image src={intergrateBanner} size="big" />}
        <p>Integrating E-Box into your institution&apos;s higher
          education curriculum brings unmatched value by combining
          traditional academic rigor with state-of-the-art AI-driven
          technology. E-Box’s innovative approach offers personalized
          learning experiences tailored to each student&apos;s unique needs,
          enhancing cognitive, analytical, and problem-solving skills essential
          for future success. Our comprehensive longitudinal digital profiles
          and advanced analytics provide educators with deep insights into each student&apos;s
          learning journey, enabling more effective teaching strategies and personalized
          support. By adopting E-Box, institutions can elevate their educational offerings,
          remain at the forefront of educational innovation, and better prepare students
          for the demands of the modern world.
        </p>
      </div>
    </div>
  );
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const renderLatestBlog = () => (
    <>
      <Header as="h1" textAlign="center">Blogs</Header>
      <Slider ref={sliderRef} {...sliderSettings}>
        {blogList.map((ele) => (
          <>
            <div className={styles.BlogContainer}>
              {!isResponsive
              && (
                <>
                  <div className={styles.blogImgSection}>
                    <Image src={ele.bannerImage} size="medium" />
                  </div>
                </>
              )}
              <div className={styles.blogTextSection}>
                <h2>{ele.name}</h2>
                {isResponsive && <Image src={ele.bannerImage} size="medium" />}
                <p>
                In the landscape of Indian higher education, where professors
                and assistant professors are often overwhelmed with administrative
                and clerical tasks, E-Box emerges as a transformative solution.
                By automating the evaluation of assignments, practical exercises,
                and research solutions, E-Box significantly reduces the clerical
                burden on educators. This enables faculty members to accelerate
                their research activities and explore more innovative teaching
                methodologies, fundamentally enhancing the educational environment.
                </p>
              </div>
            </div>
          </>
        ))}
      </Slider>
    </>
  );
  const renderLineSVG = () => (
    <div className={styles.svgContainer}>
      <svg width="1176" height="59" viewBox="0 0 1176 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 51.2812L1132 51.2812" stroke="#6936BC" strokeWidth="2" strokeLinecap="round" strokeDasharray="8.08 8.08" />
        <path d="M1131.89 51.3835C1132.23 51.6332 1132.7 51.5755 1132.95 51.2411L1135.73 47.4601L1144.97 43.533C1145.08 43.4845 1145.16 43.4394 1145.24 43.3509L1168.04 16.7872L1158.33 8.47938L1136 35.4963C1135.91 35.6036 1135.84 35.7356 1135.82 35.8731L1134.31 46.8175C1134.31 46.8175 1134.33 46.8234 1134.33 46.8328L1131.77 50.3235C1131.52 50.6578 1131.58 51.1303 1131.92 51.38L1131.89 51.3835ZM1135.97 45.7089L1137.2 37.1453C1137.63 37.1256 1139.04 36.6409 1141.59 38.8372C1144.21 41.0854 1143.79 42.223 1143.83 42.3593L1135.97 45.7089ZM1140.38 33.912C1140.19 33.7501 1140.16 33.461 1140.32 33.2651L1157.31 13.3801C1157.47 13.1936 1157.76 13.1607 1157.95 13.3256C1158.14 13.4875 1158.17 13.7766 1158.01 13.9725L1141.02 33.8575C1140.86 34.044 1140.57 34.0769 1140.38 33.912ZM1142.99 36.1167C1142.8 35.9548 1142.77 35.6657 1142.93 35.4698L1159.92 15.5847C1160.08 15.3982 1160.38 15.3807 1160.58 15.5455C1160.77 15.7075 1160.78 15.9812 1160.62 16.1677L1143.64 36.0528C1143.47 36.2393 1143.19 36.2722 1142.99 36.1073L1142.99 36.1167ZM1145.6 38.3213C1145.41 38.1594 1145.38 37.8703 1145.54 37.6745L1162.53 17.7894C1162.69 17.6029 1162.99 17.5853 1163.19 17.7502C1163.38 17.9121 1163.39 18.1859 1163.23 18.3724L1146.25 38.2574C1146.08 38.4439 1145.8 38.4768 1145.6 38.3119L1145.6 38.3213Z" fill="#263238" />
        <path d="M1168.07 17.5891C1167.77 17.9679 1167.18 18.012 1166.79 17.6916L1157.61 10.219C1157.2 9.89566 1157.14 9.31749 1157.44 8.93862L1157.9 8.37031C1158.21 7.99144 1158.79 7.9474 1159.18 8.26777L1168.36 15.7404C1168.77 16.0637 1168.83 16.6419 1168.53 17.0208L1168.07 17.5891Z" fill="#FF416E" />
        <path d="M1169.96 15.2766C1169.66 15.6554 1169.08 15.6995 1168.68 15.3791L1159.5 7.9065C1159.1 7.58318 1159.03 7.00501 1159.34 6.62614L1159.8 6.05782C1160.1 5.67895 1160.68 5.63492 1161.08 5.95528L1170.26 13.4279C1170.66 13.7512 1170.73 14.3294 1170.42 14.7083L1169.96 15.2766Z" fill="#FF416E" />
        <path d="M1161.93 5.19512L1163.91 2.75422C1165.31 1.04583 1167.82 0.78541 1169.53 2.17993L1172.46 4.56614C1174.17 5.96066 1174.43 8.47897 1173.03 10.1874L1171.05 12.6283L1161.92 5.20452L1161.93 5.19512Z" stroke="#263238" strokeWidth="1.04497" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 46.7812L11 56.7812M11 46.7812L1 56.7812" stroke="#FF416E" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </div>
  );
  const renderFeatures = () => (
    <div className={styles.plateformContainer}>
      {isResponsive
        ? (
          <>
            <div>
              <h1 className={styles.platformHeader}>Platform</h1>
              {platformData[0].content
                .map((e, index) => (
                  <div
                    onClickCapture={() => setSelectedPlatform(index)} key={e.id}
                    className={selectedPlatform === index
                      ? styles.activePlatformLi : styles.platformLi}
                  >{e.label}
                  </div>
                ))}
              <h3 className={styles.platformHeading}>
                {platformData[0].content[selectedPlatform].label}
              </h3>
              <Image src={platformData[0].content[selectedPlatform].image} />
              <p>{platformData[0].content[selectedPlatform].body}</p>
            </div>
            <div className={styles.clr20} />
            <div>
              <h1 className={styles.platformHeader}>Education Model</h1>
              {platformData[1].content
                .map((e, index) => (
                  <div
                    onClickCapture={() => setSelectedModel(index)} key={e.id}
                    className={selectedModel === index
                      ? styles.activemodelLi : styles.platformLi}
                  >{e.label}
                  </div>
                ))}
              <h3 className={styles.modelHeading}>
                {platformData[1].content[selectedModel].label}
              </h3>
              <Image src={platformData[1].content[selectedModel].image} />
              <p>{platformData[1].content[selectedModel].body}</p>
            </div>
          </>
        )
        : (
          <>
            <Grid verticalAlign="middle">
              <h1 className={styles.platformHeader}>Platform</h1>
              <Grid.Row>
                <Grid.Column computer={5}>
                  {platformData[0].content
                    .map((e, index) => (
                      <div
                        onClickCapture={() => setSelectedPlatform(index)} key={e.id}
                        className={selectedPlatform === index
                          ? styles.activePlatformLi : styles.platformLi}
                      >{e.label}
                      </div>
                    ))}
                </Grid.Column>
                <Grid.Column computer={7}>
                  <h3 className={styles.platformHeading}>
                    {platformData[0].content[selectedPlatform].label}
                  </h3>
                  <p>{platformData[0].content[selectedPlatform].body}</p>
                </Grid.Column>
                <Grid.Column computer={4}>
                  <Image src={platformData[0].content[selectedPlatform].image} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className={styles.clr20} />
            <Grid verticalAlign="middle">
              <h1>Educational Model</h1>
              <Grid.Row>
                <Grid.Column computer={5}>
                  {platformData[1].content
                    .map((e, index) => (
                      <div
                        className={selectedModel === index
                          ? styles.activemodelLi : styles.platformLi}
                        onClickCapture={() => setSelectedModel(index)} key={e.id}
                      >{e.label}
                      </div>
                    ))}
                </Grid.Column>
                <Grid.Column computer={7}>
                  <h3 className={styles.modelHeading}>
                    {platformData[1].content[selectedModel].label}
                  </h3>
                  <p>{platformData[1].content[selectedModel].body}</p>
                </Grid.Column>
                <Grid.Column computer={4}>
                  <Image src={platformData[1].content[selectedModel].image} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
    </div>
  );
  const renderPrograms = () => (
    <>
      {isResponsive
        ? (
          <>
            <div className={styles.programTxt}>Programs</div>
            {programData.map((ele) => (
              <>
                <Container className={styles.programMobSection}>
                  <h1 className={styles.header}>{ele.name}</h1>
                  <h2 className={styles.subHeader}>{ele.subName}</h2>
                  <Image src={ele.image} />
                  <p>{ele.content}</p>
                </Container>
              </>
            ))}
          </>
        ) : (
          <>
            <div className={styles.programSection}>
              <div className={styles.programTxt}>Programs</div>
              <Grid verticalAlign="middle" padded columns={2}>
                {programData?.map((ele, idx) => (
                  <>
                    {idx % 2 === 0
                      ? (
                        <>
                          <Grid.Column computer={6}>
                            <Image size="large" src={ele.image} />
                          </Grid.Column>
                          <Grid.Column computer={10}>
                            <h1 className={styles.header}>{ele.name}</h1>
                            <h2 className={styles.subHeader}>{ele.subName}</h2>
                            <p>{ele.content}</p>
                          </Grid.Column>
                        </>
                      ) : (
                        <>
                          <Grid.Column computer={10}>
                            <h1 className={styles.header}>{ele.name}</h1>
                            <h2 className={styles.subHeader}>{ele.subName}</h2>
                            <p>{ele.content}</p>
                          </Grid.Column>
                          <Grid.Column computer={6}>
                            <Image size="large" src={ele.image} />
                          </Grid.Column>
                        </>
                      )}
                  </>
                ))}
              </Grid>
            </div>
          </>
        )}
    </>
  );
  const renderBlgSlideBtn = () => (
    <div className={styles.SlideBtnContiner}>
      <svg
        cursor="pointer" width="122" height="46" viewBox="0 0 122 46" fill="none" xmlns="http://www.w3.org/2000/svg"
        onClickCapture={() => sliderRef.current.slickPrev()}
      >
        <g filter="url(#filter0_d_3075_5689)">
          <rect
            x="11" y="8" width="99.9305" height="23.9498" rx="11.9749" fill="#FF416E"
            shapeRendering="crispEdges"
          />
          <path d="M37.2358 19.2695C36.8452 19.66 36.8452 20.2931 37.2358 20.6837L43.5997 27.0476C43.9902 27.4382 44.6234 27.4382 45.0139 27.0476C45.4045 26.6571 45.4045 26.0239 45.0139 25.6334L39.3571 19.9766L45.0139 14.3197C45.4045 13.9292 45.4045 13.296 45.0139 12.9055C44.6234 12.515 43.9902 12.515 43.5997 12.9055L37.2358 19.2695ZM83.9863 18.9766L37.9429 18.9766V20.9766L83.9863 20.9766V18.9766Z" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d_3075_5689" x="0.521965" y="0.515689" width="120.886" height="44.9092" filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="2.99372" />
            <feGaussianBlur stdDeviation="5.23902" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3075_5689" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3075_5689" result="shape" />
          </filter>
        </defs>
      </svg>
      <svg
        cursor="pointer" width="122" height="46" viewBox="0 0 122 46" fill="none" xmlns="http://www.w3.org/2000/svg"
        onClickCapture={() => sliderRef.current.slickNext()}
      >
        <g filter="url(#filter0_d_3075_5691)">
          <rect
            x="10.9297" y="8" width="99.9305" height="23.9498" rx="11.9749" fill="#FF416E"
            shapeRendering="crispEdges"
          />
          <path d="M84.6236 20.6837C85.0141 20.2931 85.0141 19.66 84.6236 19.2695L78.2597 12.9055C77.8691 12.515 77.236 12.515 76.8454 12.9055C76.4549 13.296 76.4549 13.9292 76.8454 14.3197L82.5023 19.9766L76.8454 25.6334C76.4549 26.0239 76.4549 26.6571 76.8454 27.0476C77.236 27.4382 77.8691 27.4382 78.2597 27.0476L84.6236 20.6837ZM37.873 20.9766H83.9165V18.9766H37.873V20.9766Z" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d_3075_5691" x="0.451653" y="0.515689" width="120.886" height="44.9092" filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="2.99372" />
            <feGaussianBlur stdDeviation="5.23902" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3075_5691" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3075_5691" result="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );

  return (
    <div>
      <HeaderComponent />
      <EarleyYears />
      {renderMainContent()}
      {renderCampusDiv()}
      {renderPrograms()}
      {renderIntegrate()}
      <Container>
        {renderFeatures()}
        {renderLatestBlog()}
      </Container>
      {renderBlgSlideBtn()}
      {renderLineSVG()}
      <Footer />
    </div>
  );
};

export default HomeScreen;
